import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, NavLink, useNavigate } from 'react-router-dom';
import { isDefined, isEmpty } from '../../helpers/common';
import { useAuth } from '../../hooks/useAuth';
import { useConfirmation } from '../../hooks/useConfirmation';
import { PageColors } from '../../models/common';
import classNames from 'classnames';

/**
 * Main (upper-most) header layout
 * @param {(value: (((prevState: number) => number) | number)) => void} setHeight
 * @param {Partial<HeaderLogo> | undefined} logo
 * @param {Partial<HeaderLink> | undefined} link
 * @param {Partial<HeaderSubNav> | undefined} subNav
 * @param {boolean | undefined} compact
 * @return {JSX.Element}
 * @constructor
 */
const HeaderLayout: React.FC<HeaderLayoutProps & { setHeight: Dispatch<SetStateAction<number>> }> = ({ setHeight, logo, link, subNav, compact }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [scrolled, setScrolled] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const confirm = useConfirmation();
	const authGuard = useAuth();

	// listen for scroll
	useEffect(() => {
		window.addEventListener('scroll', () => setScrolled(window.scrollY > 0));
	}, []);

	// update nav height
	useEffect(() => {
		setHeight(containerRef.current?.getBoundingClientRect().height || 90);
	});

	return (
		<Container
			fluid
			ref={containerRef}
			className={classNames('pass__header', { 'pass__header--scrolled': scrolled, 'pass__header--compact': compact })}
		>
			<div className="header__container">
				{/* logo */}
				{isDefined(logo) && !isEmpty(logo?.url) && (
					<span
						className="header__logo"
						style={{
							cursor: logo?.to === 'null' ? 'normal' : 'pointer',
							height: isDefined(logo?.height) ? `${logo.height}px` : isDefined(logo?.width) ? 'auto' : '30px',
							width: isDefined(logo?.width) ? `${logo.width}px` : 'auto',
						}}
						onClick={() => {
							if (!logo?.to || logo?.to === 'null') return;

							// external redirect
							if (typeof logo.to === 'string' && /http|https|www/.test(logo.to)) {
								window.location.href = logo.to;
							} else {
								// internal navigate
								navigate(logo.to);
							}
						}}
					>
						{<img src={logo?.url} alt={logo?.alt || t('slogan')} style={{ height: isDefined(logo?.width) ? undefined : '100%' }} />}
					</span>
				)}

				{/* sub-nav */}
				{isDefined(subNav) && (isDefined(subNav.title) || subNav.links?.length) && (
					<div className="header__sub-nav">
						<h2 className="header__sub-nav-title">{subNav.title}</h2>

						{/* links */}
						<div className="header__sub-nav-links">
							{subNav.links?.map((link, key) =>
								isDefined(link.to) ? (
									<NavLink to={link.to} key={key} className="header__link mt-2" activeClassName="header__link--active">
										<button className="pass-button">{link.title}</button>
									</NavLink>
								) : (
									<a
										key={key}
										className={classNames('header__link mt-2', {
											'header__link--active': link.active,
										})}
										onClick={link.do}
									>
										<button className="pass-button">{link.title}</button>
									</a>
								),
							)}
						</div>
					</div>
				)}

				{/* main link */}
				{isDefined(link) && !isEmpty(link?.title) && !isEmpty(link?.to) && (
					<Link to={link.to} className="header__link header__link--main ml-auto">
						<button
							className="pass-button"
							style={{
								backgroundColor: link?.colors?.background?.toString(),
								color: link?.colors?.text?.toString(),
								borderColor: link?.colors?.text?.toString(),
							}}
						>
							{link.title}
						</button>
					</Link>
				)}
			</div>
		</Container>
	);
};

interface HeaderLink {
	title: string;
	colors?: Partial<PageColors>;
	to: LinkProps['to'];
}

interface HeaderLogo {
	alt: string;
	url: string;
	to: LinkProps['to'];
	width: number;
	height: number;
}

interface HeaderSubNav {
	title: string;
	links: HeaderSubLink[];
}

interface HeaderSubLink {
	title: string;
	to?: string;
	do?: () => void;
	active?: boolean;
}

export interface HeaderLayoutProps {
	link?: Partial<HeaderLink>;
	logo?: Partial<HeaderLogo>;
	subNav?: Partial<HeaderSubNav>;
	compact?: boolean;
}

export default HeaderLayout;
