import React, { useEffect } from 'react';
import { useHistory } from '../../router/components/BrowserRouter';

/**
 * Restores scroll position on route change
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 * @constructor
 */
const ScrollRestore: React.FC = ({ children }) => {
	const history = useHistory();

	useEffect(() => {
		const removeListener = history.listen(() => {
			window.scrollTo(0, 0);
		});

		return () => {
			removeListener();
		};
	}, []);

	return <React.Fragment>{children}</React.Fragment>;
};

export default ScrollRestore;
