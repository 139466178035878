import axios, { AxiosInstance } from 'axios';
import { isEmpty } from '../helpers/common';
import { TOKEN_KEY } from '../providers/AuthProvider';

/**
 * API endpoint URL (e.g. https://api.test.nfctron.com)
 */
export const API_URL = process.env.REACT_APP_API_URL;

/**
 * API path endpoint (e.g. /pass-v2)
 * @type {string}
 */
export const API_PATH = process.env.REACT_APP_API_VER;

// global axios configuration
const apiClient: AxiosInstance = axios.create({
	baseURL: `${API_URL}${API_PATH}`,
});

// request interceptor - add auth token to every call
apiClient.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem(TOKEN_KEY);
		if (!isEmpty(token)) config.headers.common['Authorization'] = token;
		// FIXME: delete `cancelToken` if presented by useAxios hook, but why?
		delete config.cancelToken;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// response interceptor - format error
apiClient.interceptors.response.use(
	(res) => res,
	(error) => {
		if (error.response) {
			error = new APIRequestError(
				error.response.status === 401
					? 'unauthorized'
					: error.response.status === 400
					? 'bad_request'
					: error.response.status === 500
					? 'internal_error'
					: 'response_fail',
				error.response.data?.message || 'The server returned an error response and provided no further information.',
				error.response.status,
			);
		} else if (error.request) {
			error = new APIRequestError('no_response', 'The request was made but no response was received');
		} else if (error?.__proto__?.__CANCEL__ === true) {
			error = new APIRequestError('request_canceled', 'The request was canceled.');
		} else {
			error = new APIRequestError('request_fail', 'Something happened in setting up the request that triggered an error.');
		}
		return Promise.reject(error);
	},
);

/**
 * API Request Error
 */
export class APIRequestError extends Error {
	constructor(public name: string, public message: string, public status: number = 500) {
		super();
	}
}

export default apiClient;
