import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isDefined } from '../helpers/common';

/**
 * Custom useState hook, that works with as async Promise function
 * @see https://ysfaran.github.io/blog/post/0002-use-state-with-promise/
 * @param {T} initialState
 * @returns {[T, ((nextValue: T) => Promise<T>)]}
 */
const useStateWithPromise = <T = any>(initialState: T): [T, (nextValue: T) => Promise<T>] => {
	const [state, setState] = useState<T>(initialState);
	const resolverRef = useRef<any>(null);

	useEffect(() => {
		if (isDefined(resolverRef.current)) {
			resolverRef.current(state);
			resolverRef.current = null;
		}
		/**
		 * Since a state update could be triggered with the exact same state again,
		 * it's not enough to specify state as the only dependency of this useEffect.
		 * That's why resolverRef.current is also a dependency, because it will guarantee,
		 * that handleSetState was called in previous render
		 */
	}, [resolverRef.current, state]);

	const handleSetState = useCallback(
		(stateAction): Promise<T> => {
			setState(stateAction);
			return new Promise((resolve) => {
				resolverRef.current = resolve;
			});
		},
		[setState],
	);

	return [state, handleSetState];
};

export default useStateWithPromise;
