import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../helpers/common';
import i18n from '../../common/i18n';
import { uniqBy } from 'lodash';

/**
 * modify DOM's <head> content on the go
 * @param {string | undefined} rawTitle
 * @param {MetaProps[] | undefined} meta
 * @returns {JSX.Element}
 * @constructor
 */
const ReactHelmetHead: React.FC<ReactHelmetHeadProps> = ({ title: rawTitle, meta = [] }) => {
	const { t } = useTranslation();
	const appName = process.env.APP_NAME || process.env.REACT_APP_APP_NAME;
	const title = !isEmpty(rawTitle) ? `${rawTitle} | ${appName}` : appName;

	// declare default global meta tags
	const defaultMeta: ReactHelmetHeadProps['meta'] = [
		{ name: 'description', content: t('meta.description') },
		{ property: 'og:title', content: title },
		{ property: 'og:type', content: 'website' },
		{ property: 'og:description', content: t('meta.ogDescription') },
		{ property: 'og:image', content: t('meta.ogImage') },
		{
			property: 'og:url',
			content: (() => {
				// get current url
				const url = new URL(window.location.href);

				// hard-code `https` protocol
				url.protocol = 'https';
				return url.toString();
			})(),
		},
		{ property: 'og:locale', content: i18n.language },
		{ property: 'og:site_name', content: appName },
		{ name: 'twitter:card', content: 'summary_large_image' },
	];

	// use current meta
	const currentMeta: ReactHelmetHeadProps['meta'] = uniqBy([...meta, ...defaultMeta], (v) => v.property || v.name);

	return (
		<Helmet meta={currentMeta}>
			<title>{title}</title>
		</Helmet>
	);
};

export interface ReactHelmetHeadProps {
	title?: HelmetProps['title'];
	meta?: HelmetProps['meta'];
}
export default ReactHelmetHead;
