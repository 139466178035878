import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import usePromise from 'react-promise-suspense';
import { ToastContainer } from 'react-toastify';
import { initializeLocize } from './common/i18n';
import { initializeSentry, SentryErrorFallback } from './common/sentry';
import ScrollRestore from './components/layout/ScrollRestore';
import { OverlaySuspense } from './components/loaders';
import './core.scss';
import AuthProvider from './providers/AuthProvider';
import { ConfirmationServiceProvider } from './providers/ConfirmationProvider';
import LayoutProvider from './providers/LayoutProvider';
import reportWebVitals from './reportWebVitals';
import BrowserRouter from './router/components/BrowserRouter';

/**
 * Core application wrapper
 * @return {JSX.Element}
 * @constructor
 */
const Core: React.FC = () => {
	// suspend while initializing
	usePromise(async () => {
		await initializeLocize();
		await initializeSentry();
	}, []);

	// needed to be imported after core initialization finishes
	// in order to have further access to TFunction
	const ModularRouter = React.lazy(() => import('./router'));

	return (
		<ScrollRestore>
			<LayoutProvider>
				<ModularRouter />
			</LayoutProvider>
		</ScrollRestore>
	);
};

// root application
ReactDOM.render(
	<Sentry.ErrorBoundary fallback={SentryErrorFallback}>
		<React.Suspense fallback={<OverlaySuspense />}>
			<HelmetProvider>
				<ConfirmationServiceProvider>
					<ToastContainer hideProgressBar closeButton={false} />
					<BrowserRouter>
						<AuthProvider>
							{() => (
								<>
									<Core />
								</>
							)}
						</AuthProvider>
					</BrowserRouter>
				</ConfirmationServiceProvider>
			</HelmetProvider>
		</React.Suspense>
	</Sentry.ErrorBoundary>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
