import React, { createContext, useEffect, useState } from 'react';
import apiClient from '../common/apiClient';
import { LANGUAGES } from '../common/constants';
import i18n from '../common/i18n';
import { isDefined, isEmpty } from '../helpers/common';
import useStateWithPromise from '../hooks/useStateWithPromise';
import { Account } from '../models/common';
import { Account as APIAccount } from '../models/passAPI';

/**
 * AuthProvider Context
 * @type {React.Context<Partial<AuthProviderState>>}
 */
const AuthContext = createContext<AuthProviderState>({
	token: null,
	userId: null,
	authenticated: false,
	authenticating: false,
	// @ts-ignore
	updateToken: async () => {},
});

/**
 * Token storage key
 * @type {string}
 */
export const TOKEN_KEY = 'token';

/**
 * Auth Provider
 * @returns {JSX.Element}
 * @constructor
 */
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [token, setToken] = useStateWithPromise<string | null>(null);
	const [userId, setUserId] = useState<string | null>(null);
	const [user, setUser] = useState<Account | null>(null);
	const [authenticating, setAuthenticating] = useState(true);
	const persistStrategy = localStorageStrategy(TOKEN_KEY);

	// on component mount
	useEffect(() => {
		(async () => {
			await setToken(await persistStrategy.get());
		})();
	}, []);

	// on token change
	useEffect(() => {
		(async () => {
			try {
				if (!isEmpty(token)) await fetchUser();
			} catch (e) {}
		})();
	}, [token]);

	/**
	 * Fetches user info, also used for token validation
	 * @returns {Promise<any>}
	 */
	const fetchUser = async (dontUpdateLang?: boolean) => {
		try {
			const { data } = await apiClient.get<APIAccount.AccountByToken['Output']>('/account-by-token');
			// on success
			setUserId(data.accountId as string);
			setUser(data);
			if (!dontUpdateLang) {
				// update display language
				await i18n.changeLanguage(LANGUAGES().find(({ languageId }) => languageId === data.languageId)?.code || 'cs');
			}
			setAuthenticating(false);

			// return info
			return data;
		} catch (error) {
			// on failure
			onFailure();
			throw error;
		}
	};

	/**
	 * onFailure handler
	 */
	const onFailure = () => {
		persistStrategy.clear();
		setUserId(null);
		setAuthenticating(false);
		setToken(null);
	};

	/**
	 * Returns current provider state
	 * @returns {AuthProviderState}
	 */
	const getProviderState = (): AuthProviderState => ({
		token,
		userId,
		authenticating,
		authenticated: isDefined(token) && isDefined(userId) && !authenticating,
		updateToken,
		logout: () => {
			onFailure();
		},
		getUser: () => user,
		fetchUser: async (dontUpdateLang) => {
			try {
				return fetchUser(dontUpdateLang);
			} catch (err) {
				return null;
			}
		},
	});

	/**
	 * Updates token and returns updated provider state
	 * @param {AuthProviderState["token"]} token
	 * @returns {Promise<AuthProviderState>}
	 */
	const updateToken = async (token: AuthProviderState['token']) => {
		return Promise.resolve(await storeToken(token));
	};

	/**
	 * Stores token and returns updated provider state
	 * @param {AuthProviderState["token"]} token
	 * @returns {Promise<AuthProviderState>}
	 */
	const storeToken = (token: AuthProviderState['token']): Promise<AuthProviderState> =>
		new Promise(async (resolve) => {
			if (isDefined(token)) persistStrategy.persist(token);
			await setToken(token);
			return resolve(getProviderState());
		});

	return <AuthContext.Provider value={getProviderState()}>{isDefined(children) && children(getProviderState())}</AuthContext.Provider>;
};

/**
 * LocalStorage persistence strategy
 * @param {string} key
 * @returns {{get(): Promise<string | null>, clear(): void, persist(token: string): void}}
 */
const localStorageStrategy = (key: string) => ({
	get(): Promise<string | null> {
		return new Promise((resolve) => resolve(localStorage.getItem(key)));
	},
	persist(token: string) {
		localStorage.setItem(key, token);
	},
	clear() {
		localStorage.removeItem(key);
	},
});

interface AuthProviderProps {
	children: (state: AuthProviderState) => React.ReactNode;
}

export interface AuthProviderState {
	token: string | null;
	userId: string | null;
	authenticating: boolean;
	authenticated: boolean;
	updateToken: (token: AuthProviderState['token']) => Promise<this>;
	getUser: () => Account | null;
	fetchUser: (dontUpdateLang?: boolean) => Promise<Account | null>;
	logout: () => void;
}

// exports
export default AuthProvider;

const { Consumer: AuthConsumer } = AuthContext;
export { AuthConsumer, AuthContext };
