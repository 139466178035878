import Color from 'color';
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import FooterLayout, { FooterLayoutProps } from '../components/layout/FooterLayout';
import HeaderLayout, { HeaderLayoutProps } from '../components/layout/HeaderLayout';
import ReactHelmetHead, { ReactHelmetHeadProps } from '../components/layout/ReactHelmetHead';
import { changeGlobalColorScheme } from '../helpers/colors';
import { PageColors } from '../models/common';

/**
 * Layout context
 * @type {React.Context<LayoutProviderControls>}
 */
const LayoutContext = createContext<LayoutProviderControls>(
	// @ts-ignore
	{},
);

/**
 * Layout provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
const LayoutProvider: React.FC = ({ children }) => {
	// header control
	const [headerLogo, setHeaderLogo] = useState<HeaderLayoutProps['logo']>();
	const [headerLink, setHeaderLink] = useState<HeaderLayoutProps['link']>();
	const [headerSubNav, setHeaderSubNav] = useState<HeaderLayoutProps['subNav']>();
	const [headerCompact, setHeaderCompact] = useState<HeaderLayoutProps['compact']>();

	// footer control
	const [footerData, setFooterData] = useState<FooterLayoutProps['data']>();

	// page control
	const [pageTitle, setPageTitle] = useState<ReactHelmetHeadProps['title']>();
	const [pageMeta, setPageMeta] = useState<ReactHelmetHeadProps['meta']>();
	const [pageColors, setPageColors] = useState<Partial<PageColors & { footer: Color }>>();

	// update color scheme
	useEffect(() => {
		changeGlobalColorScheme(pageColors?.background, pageColors?.text, pageColors?.footer);
	}, [pageColors]);

	// misc
	const [navHeight, setNavHeight] = useState(90);

	return (
		<LayoutContext.Provider
			value={{
				headerControl: {
					link: { get: headerLink, set: setHeaderLink },
					logo: { get: headerLogo, set: setHeaderLogo },
					subNav: { get: headerSubNav, set: setHeaderSubNav },
					compact: { get: headerCompact, set: setHeaderCompact },
				},
				footerControl: {
					data: { get: footerData, set: setFooterData },
				},
				pageControl: {
					title: { get: pageTitle, set: setPageTitle },
					meta: { get: pageMeta, set: setPageMeta },
					colors: { get: pageColors, set: setPageColors },
				},
			}}
		>
			<Container fluid className="pass__container">
				{/* page head */}
				<ReactHelmetHead title={pageTitle} meta={pageMeta} />

				{/* header */}
				<HeaderLayout setHeight={setNavHeight} compact={headerCompact} logo={headerLogo} link={headerLink} subNav={headerSubNav} />

				{/* main */}
				<main id="pass__body" style={{ marginTop: `${navHeight}px` }}>
					{children}
				</main>
			</Container>

			{/* footer */}
			<FooterLayout data={footerData} />
		</LayoutContext.Provider>
	);
};

/**
 * Layout provider controls interface
 */
export interface LayoutProviderControls {
	headerControl: {
		[key in keyof Required<HeaderLayoutProps>]: {
			get: HeaderLayoutProps[key];
			set: Dispatch<SetStateAction<HeaderLayoutProps[key]>>;
		};
	};
	footerControl: {
		[key in keyof Required<FooterLayoutProps>]: {
			get: FooterLayoutProps[key];
			set: Dispatch<SetStateAction<FooterLayoutProps[key]>>;
		};
	};
	pageControl: {
		[key in keyof Required<ReactHelmetHeadProps>]: {
			get: ReactHelmetHeadProps[key];
			set: Dispatch<SetStateAction<ReactHelmetHeadProps[key]>>;
		};
	} & {
		colors: {
			get: Partial<PageColors & { footer: Color }> | undefined;
			set: Dispatch<SetStateAction<Partial<PageColors & { footer: Color }> | undefined>>;
		};
	};
}

// exports
export default LayoutProvider;

const { Consumer: LayoutConsumer } = LayoutContext;
export { LayoutConsumer, LayoutContext };
