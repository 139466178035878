import React, { createContext, useContext, useRef, useState, useLayoutEffect } from 'react';
import { BrowserHistory, createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

/**
 * History context
 * @type {React.Context<BrowserHistory>}
 */
const HistoryContext = createContext<BrowserHistory>(
	// @ts-ignore
	{},
);

/**
 * Custom useHistory context for history management
 * @return {BrowserHistory<State>}
 */
export const useHistory = () => useContext(HistoryContext);

/**
 * Customized BrowserRouter to expose `history`
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 * @see https://github.com/ReactTraining/react-router/issues/7476
 * @constructor
 */
const BrowserRouter: React.FC = ({ children }) => {
	const { current: history } = useRef(createBrowserHistory({ window }));
	const [{ action, location }, setHistoryState] = useState({
		action: history.action,
		location: history.location,
	});

	useLayoutEffect(() => history.listen(setHistoryState), [history]);

	return (
		<Router action={action} location={location} navigator={history}>
			<HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
		</Router>
	);
};

export default BrowserRouter;
