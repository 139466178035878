import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/de';
import { initReactI18next } from 'react-i18next';
import { isDefined } from '../helpers/common';
import { IS_DEVELOPMENT } from './constants';

/**
 * i18next-http-backend config
 * @type {import('i18next-http-backend').BackendOptions}
 */
const i18nextHttpBackendOptions = {
	loadPath: 'https://nfctron-translation.s3.eu-central-1.amazonaws.com/{{ns}}/{{lng}}.json',
};

// i18next setup
export const initializeLocize = async () => {
	const i18nInit = await i18n
		.use(I18NextHttpBackend)
		.use(I18nextBrowserLanguageDetector)
		.use(initReactI18next)
		.init({
			// lng: 'cs',
			fallbackLng: {
				de_AT: ['de'],
				default: ['en'],
			},
			keySeparator: false,
			debug: IS_DEVELOPMENT,
			ns: ['nfctron-pass-main'],
			backend: i18nextHttpBackendOptions,
			whitelist: ['en', 'cs', 'de', 'sk'],
			defaultNS: 'nfctron-pass-main',
		});

	moment.locale(i18n.language);

	return i18nInit;
};

/**
 * Return formatted price with currency
 * @param {number} price
 * @param {string} currency
 * @param {Intl.NumberFormatOptions} settings
 * @param {string} onFree
 * @returns {any}
 */
export const formattedPrice = (price: number, currency: string, settings?: Intl.NumberFormatOptions, onFree?: string) => {
	if (price === 0 && isDefined(onFree)) return onFree;
	return Intl.NumberFormat(i18n.language, {
		style: 'currency',
		currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
		...settings,
	}).format(price / 100);
};

/**
 * Return formatted percentage
 * @param {number} amount
 * @param {Intl.NumberFormatOptions} settings
 * @param {string} onFree
 * @returns {any}
 */
export const formattedPercent = (amount: number, settings?: Intl.NumberFormatOptions, onFree?: string) => {
	if (amount === 0 && isDefined(onFree)) return onFree;
	return Intl.NumberFormat(i18n.language, {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
		...settings,
	}).format(amount / 10000);
};

export default i18n;
