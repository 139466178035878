import * as Sentry from '@sentry/react';
import { captureException, Severity } from '@sentry/react';
import { FallbackRender } from '@sentry/react/esm/errorboundary';
import React from 'react';
import { ErrorBoundaryPropsWithFallback } from 'react-error-boundary';
import { name, version } from '../../package.json';
import { useAuth } from '../hooks/useAuth';
import NFCtronIcon from '../images/icons/nfctron-icon-01.svg';
import { AuthProviderState } from '../providers/AuthProvider';
import { PassRoute } from '../router';
import { IS_DEVELOPMENT } from './constants';
import i18n from './i18n';

// sentry initialization
export const initializeSentry = () =>
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: `${name}@${version}`,
		environment: process.env.NODE_ENV,
		debug: IS_DEVELOPMENT,
		ignoreErrors: [/window\.webkit\.messageHandlers/],
	});

/**
 * Component render error class
 */
export class RenderError extends Error {
	constructor(public component: string, public message: string = 'Something went wrong while rendering the # component.') {
		super();
		this.name = this.component + 'Render';
		this.message = this.message.replace('#', this.component || '');
	}
}

/**
 * Generic on section error handler
 * @param {string} name
 * @param {PassRoute | undefined} route
 * @param {any} data
 * @param {AuthProviderState | undefined} authGuard
 * @return {(error) => void}
 */
export const onSectionError: onErrorFunc =
	({ name, route, data, authGuard }) =>
	(error) => {
		// capture and send exception to Sentry
		captureException(new RenderError(name), (scope) => {
			scope.setLevel(Severity.Error);
			scope.setExtra('caughtError', error);
			scope.setExtra('routeConfig', route);
			scope.setExtra('contextData', data);
			scope.setUser({ ...authGuard?.getUser(), token: authGuard?.token });
			scope.setTag('authenticated', authGuard?.authenticated);
			scope.setTag('crashed', 'section');
			return scope;
		});
	};

/**
 * On render error generic function
 * @export
 */
export type onErrorFunc = (
	props: { name: string } & Partial<{ route: PassRoute; data: any; authGuard: ReturnType<typeof useAuth> }>,
) => ErrorBoundaryPropsWithFallback['onError'];

/**
 * Sentry Error fallback component
 * @param {() => void} resetError
 * @param {string | null} eventId
 * @param {Error} error
 * @param {string | null} componentStack
 * @returns {JSX.Element}
 * @constructor
 */
export const SentryErrorFallback: FallbackRender = ({ resetError, eventId, error, componentStack }) => {
	return (
		<div className="d-flex h-100 d-flex justify-content-center align-items-center vh-100">
			<div className="text-center">
				<img src={NFCtronIcon} alt="NFCtron" height={192} />
				<h1 className="mt-3">{i18n.t('errorPage.title')}</h1>
				<p className="text-muted">{i18n.t('errorPage.text')}</p>
				<p className="text-muted">{i18n.t('errorCode')}:</p>
				<br />
				<pre>{eventId}</pre>

				{/* report button */}
				<button
					onClick={() => {
						Sentry.showReportDialog({
							eventId: eventId ?? undefined,
							lang: i18n.language,
						});
					}}
					className="button button--outline"
				>
					{i18n.t('errorPage.report')}
				</button>

				{/* reload button */}
				<button onClick={resetError} className="button button--continue">
					{i18n.t('errorPage.button')}
				</button>
			</div>
		</div>
	);
};
