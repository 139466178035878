import classNames from 'classnames';
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Confirm window component
 * @param {string | undefined} title
 * @param {boolean} open
 * @param {string | undefined} text
 * @param {{label?: string, disabled?: boolean} | undefined} confirm
 * @param {{label?: string, disabled?: boolean} | undefined} abort
 * @param {() => void} onConfirm
 * @param {() => void} onAbort
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmationWindow = ({ title, open, text, confirm, abort, onConfirm, onAbort }: ConfirmWindowProps) => {
	const { t } = useTranslation();
	return (
		<Modal show={open} onHide={onAbort} centered dialogClassName="conf-window">
			<Modal.Body className="text-center">
				<h3 className="mt-3">{title || t('confirmWindow.defaultTitle')}</h3>
				<p>{text || t('confirmWindow.defaultText')}</p>

				<div className="d-flex align-items-center">
					<span className={classNames('link text-center flex-grow-1', { disabled: abort?.disabled })} onClick={onAbort}>
						{abort?.label || t('cancel')}
					</span>
					<button className={classNames('button button--continue w-50', { disabled: confirm?.disabled })} onClick={onConfirm}>
						{confirm?.label || t('confirm')}
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

// confirmation service context
export const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject.bind(Promise));

/**
 * Confirmation service provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
export const ConfirmationServiceProvider = ({ children }: PropsWithChildren<{}>) => {
	const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null);

	// pending promise reference
	const awaitingPromiseRef =
		useRef<{
			resolve: () => void;
			reject: () => void;
		}>();

	// open confirmation handler
	const openConfirmation = useCallback(
		(options: ConfirmationOptions) => {
			setConfirmationState(options);
			return new Promise<void>((resolve, reject) => {
				awaitingPromiseRef.current = { resolve, reject };
			});
		},
		[setConfirmationState],
	);

	// abort handler
	const handleAbort = () => {
		if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject();
		}

		setConfirmationState(null);
	};

	// confirm handler
	const handleConfirm = () => {
		setTimeout(() => {
			if (awaitingPromiseRef.current) {
				awaitingPromiseRef.current.resolve();
			}
			// hack to simulate very tiny delay to prevent the confirmationWindow from being buggy
		}, 0);

		setConfirmationState(null);
	};

	return (
		<>
			<ConfirmationServiceContext.Provider value={openConfirmation} children={children} />

			<ConfirmationWindow open={Boolean(confirmationState)} onAbort={handleAbort} onConfirm={handleConfirm} {...confirmationState} />
		</>
	);
};

// confirmation options
export interface ConfirmationOptions {
	catchOnCancel?: boolean;
	title?: string;
	text?: string;
	confirm?: {
		label?: string;
		disabled?: boolean;
	};
	abort?: {
		label?: string;
		disabled?: boolean;
	};
}

export interface ConfirmWindowProps extends ConfirmationOptions {
	open: boolean;
	onConfirm: () => void;
	onAbort: () => void;
}
