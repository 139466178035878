import { i18n, TFunction } from 'i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../common/constants';
import { isDefined } from '../../helpers/common';
import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../hooks/useAuth';
import { Account as APIAccount } from '../../models/passAPI';
import Button from '../Button';

/**
 * Main footer layout
 * @param {any} data
 * @returns {JSX.Element}
 * @constructor
 */
const FooterLayout: React.FC<FooterLayoutProps> = ({ data }) => {
	const { t, i18n } = useTranslation();
	const [langModalOpen, setLangModalOpen] = useState(false);
	const { authenticated, getUser, fetchUser, userId } = useAuth();
	const [lang, setLang] = useState(i18n.language);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);

	/**
	 * Account set data API request
	 */
	const [, accountSetDataAPI] = useAPI<APIAccount.AccountSetData>(
		{
			url: '/account-set-data',
			method: 'POST',
		},
		{
			manual: true,
		},
	);

	return (
		<>
			<Modal show={langModalOpen} onHide={() => setLangModalOpen(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<h3>{t('langModal.title')}</h3>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-muted">{t('langModal.text')}</p>
					<select
						value={lang}
						onChange={async (e) => {
							const refUser = authenticated ? getUser() || undefined : undefined;
							const lng = e.target.value || 'cs';

							await i18n.changeLanguage(lng, async () => {
								if (authenticated && isDefined(refUser) && isDefined(userId)) {
									// update languageId in ticket.account
									// @ts-ignore
									delete refUser.firstLogin;
									// @ts-ignore
									delete refUser.email;

									await accountSetDataAPI({
										data: {
											...refUser,
											accountId: userId,
											languageId: LANGUAGES().find(({ code }) => code === lng)?.languageId || LANGUAGES()[0].languageId,
										},
									});
									// to update lang
									await fetchUser();
								}
								setLangModalOpen(false);
							});
						}}
					>
						{LANGUAGES().map((lang, key) => (
							<option value={lang.code} key={key}>
								{lang.name}
							</option>
						))}
					</select>
				</Modal.Body>
				<Modal.Footer>
					<Button label={t('close')} className="button--continue" onClick={() => setLangModalOpen(false)} />
				</Modal.Footer>
			</Modal>

			{isDefined(data) ? (
				<footer id="pass__footer" className="pass__footer--internal">
					<Container className="footer__container">
						{data}
						<div className="footer__copyright">
							<p>
								NFCtron &copy; {new Date().getFullYear()}
								<br />
								<a href="https://www.nfctron.com/cs/podminky/nfctron-tickets-vseobecne-podminky-navstevnik" target="_blank">
									Obchodní podmínky
									<br />a zásady NFCtron Pass
								</a>
								<br />
								<a href="#" onClick={(e) => setLangModalOpen(true)}>
									{t('footerLinks.changeLanguage')}
								</a>
							</p>
						</div>
					</Container>
				</footer>
			) : (
				defaultFooter(t, () => setLangModalOpen(true))
			)}
		</>
	);
};

/**
 * Default global footer
 * @param {TFunction} t
 * @param {() => void} changeLanguage
 * @return {JSX.Element}
 */
const defaultFooter = (t: TFunction, changeLanguage: () => void) => {
	return (
		<div id="pass__footer" className="pass__footer pass__footer--global">
			<footer>
				<div className="footer__container">
					<div className="footer__item footer__item--overview">
						<img src="https://www.nfctron.com/img/svg/nfctron-logo.svg?v=2" alt="logo" className="footer__logo" />
						<h4>{t('footer.overview')}</h4>
						<p>
							<a href="https://support.nfctron.com/" target="_blank">
								{t('footerLinks.help')}
							</a>
							<br />
							<a href="#" onClick={(e) => changeLanguage()}>
								{t('footerLinks.changeLanguage')}
							</a>
							<br />
							<a href="https://www.nfctron.com/cs/podminky/nfctron-tickets-vseobecne-podminky-navstevnik" target="_blank">
								{t('footerLinks.policies')}
							</a>
						</p>
						<p className="footer__copyright">NFCtron &copy; {new Date().getFullYear()}</p>
					</div>
					<div className="footer__item footer__item--social">
						<h4>{t('footer.followOurFlight')}</h4>
						<p>
							{/* Instagram */}
							<a href="https://www.instagram.com/nfctron/" target="_blank" rel="noopener">
								<svg width="32" height="32" viewBox="0 0 32 32">
									<path
										fill="#50C878"
										d="M27.31 0H4.7A4.7 4.7 0 000 4.69V27.3A4.7 4.7 0 004.69 32H27.3A4.7 4.7 0 0032 27.31V4.7A4.7 4.7 0 0027.31 0zM16.06 24.38a8.44 8.44 0 118.44-8.44 8.45 8.45 0 01-8.44 8.44zm9.38-15a2.81 2.81 0 112.81-2.82 2.82 2.82 0 01-2.81 2.82zm0 0"
									/>
									<path
										fill="#50C878"
										d="M25.44 5.63a.94.94 0 10.94.93.94.94 0 00-.94-.94zm0 0M16.06 9.38a6.56 6.56 0 106.57 6.56 6.57 6.57 0 00-6.57-6.56zm0 0"
									/>
								</svg>
							</a>
							{/* Facebook */}
							<a href="https://www.facebook.com/nfctron/" target="_blank" rel="noopener">
								<svg width="32" height="32" viewBox="0 0 32 32">
									<path
										fill="#50C878"
										d="M27.31 0H4.7A4.7 4.7 0 000 4.69V27.3A4.7 4.7 0 004.69 32h9.44V20.69h-3.75v-5.63h3.75v-3.81a5.63 5.63 0 015.62-5.63h5.69v5.63h-5.69v3.81h5.69l-.94 5.63h-4.75V32h7.56A4.7 4.7 0 0032 27.31V4.7A4.7 4.7 0 0027.31 0zm0 0"
									/>
								</svg>
							</a>
							{/* YouTube */}
							<a href="https://www.youtube.com/channel/UCOWtYvVU_t_rXr_gUf3dCDw" target="_blank" rel="noopener">
								<svg width="42.01" height="32" viewBox="0 0 42.01 32">
									<path
										fill="#50C878"
										d="M35.86 0H6.16A6.16 6.16 0 000 6.15v19.7A6.16 6.16 0 006.15 32h29.7a6.16 6.16 0 006.16-6.15V6.15A6.16 6.16 0 0035.86 0zm-21 24.49V7.76l14.8 8.22zm0 0"
									/>
									<path fill="#50C878" d="M17.31 11.94v8.3l7.35-4.22zm0 0" />
								</svg>
							</a>
							{/* LinkedIn */}
							<a href="https://www.linkedin.com/company/nfctron/" target="_blank" rel="noopener">
								<svg width="32" height="32" viewBox="0 0 32 32">
									<path
										fill="#50C878"
										d="M27.31 0H4.7A4.7 4.7 0 000 4.69V27.3A4.7 4.7 0 004.69 32H27.3A4.7 4.7 0 0032 27.31V4.7A4.7 4.7 0 0027.31 0zm-16 25.38H7.56V12.24h3.75zm0-15H7.56V6.62h3.75zm13.13 15h-3.75v-7.5a1.88 1.88 0 00-3.75 0v7.5h-3.75V12.24h3.75v.7a8.13 8.13 0 012.81-.7 4.9 4.9 0 014.69 4.98zm0 0"
									/>
								</svg>
							</a>
						</p>
					</div>
					<div className="footer__item footer__item--logo">
						<img src="https://www.nfctron.com/img/svg/nfctron-logo.svg?v=2" alt={t('slogan')} />
					</div>
					<div className="footer__item footer__item--contact">
						<h4>{t('footer.spaceContact')}</h4>
						<p>
							{/*<a href="tel:+420721725782">+420 721 725 782</a>*/}
							{/*<br />*/}
							<a href="mailto:info@nfctron.com">info@nfctron.com</a>
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export interface FooterLayoutProps {
	data: FooterData;
}

export type FooterData = ReactNode | any;

export default FooterLayout;
