import StaticAxios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { makeUseAxios, Options as UseAxiosOptions, RefetchOptions, ResponseValues, UseAxios } from 'axios-hooks';
import { useCallback, useEffect, useState } from 'react';
import apiClient, { APIRequestError } from '../common/apiClient';
import { IS_PRODUCTION } from '../common/constants';
import { isDefined } from '../helpers/common';

/**
 * Initialize useAxios hook
 * @type {UseAxios}
 */
const useAxios = makeUseAxios({ axios: apiClient });

/**
 * Overload signature for useAPI with data format
 * @param {UseAPIConfig<Route>} config
 * @param {UseAPIOptionsWithFormat<Route, FormattedOutput>} options
 * @return {UseAPIOutput<Route, FormattedOutput>}
 */
function useAPI<Route extends APIRoute, FormattedOutput>(
	config: UseAPIConfig<Route>,
	options: UseAPIOptionsWithFormat<Route, FormattedOutput>,
): UseAPIOutput<Route, FormattedOutput>;

/**
 * Overload signature for basic useAPI
 * @param {UseAPIConfig<Route>} config
 * @param {UseAPIOptions<Route>} options
 * @return {UseAPIOutput<Route>}
 */
function useAPI<Route extends APIRoute>(config: UseAPIConfig<Route>, options?: UseAPIOptions<Route>): UseAPIOutput<Route>;

/**
 * Custom axios-based useAPI hook
 * @param config
 * @param options
 * @return {any}
 */
function useAPI(config: any, options: any): any {
	// actual useAxios hook
	const [{ error: useAxiosError, response: useAxiosResponse }] = useAxios(config, {
		manual: options?.manual,
		useCache: options?.useCache,
		ssr: options?.ssr,
	});

	// data to return
	const [data, setData] = useState<any>();
	const [error, setError] = useState<AxiosError<APIRequestError>>();
	const [loading, setLoading] = useState<boolean>(!options?.manual);
	const [response, setResponse] = useState<AxiosResponse>();
	const rerun = useCallback(
		async (configOverride?: Partial<UseAPIConfig<any>>) => {
			try {
				// set REQUEST_START states
				setLoading(true);
				setError(undefined);

				// perform the call
				const response = await apiClient({
					...config,
					...configOverride,
				});

				// set REQUEST_END states

				// process data and response
				let data = response.data;
				if (isDefined(options?.formatResult)) {
					data = await options.formatResult(response);
				}

				if (isDefined(options?.onSuccess)) {
					await options.onSuccess(data, response);
				}

				// set states
				setData(data);
				setLoading(false);
				setResponse(response);

				// return response
				return { ...response, data };
			} catch (err) {
				if (!StaticAxios.isCancel(err)) {
					await options?.onError?.(err);
					setError(err.data);
					setData(err);
				}

				setLoading(false);
				throw err;
			}
		},
		[config],
	);

	// useAxios response received
	useEffect(() => {
		if (isDefined(useAxiosResponse)) {
			(async () => {
				// set (and format) data
				setData((await options?.formatResult?.(useAxiosResponse)) || useAxiosResponse.data);
			})();
		}
	}, [useAxiosResponse]);

	// useAxios error received
	useEffect(() => {
		if (isDefined(useAxiosError)) {
			(async () => {
				await options?.onError?.(useAxiosError);

				// stop loading
				setLoading(false);
			})();
		}
	}, [useAxiosError]);

	// output data received
	useEffect(() => {
		if (isDefined(data) && isDefined(useAxiosResponse)) {
			(async () => {
				if (!IS_PRODUCTION) console.log('[useAPI] done:', data);
				await options?.onSuccess?.(data, response);

				// stop loading
				setLoading(false);

				// finalized response
				setResponse(useAxiosResponse);
			})();
		}
	}, [data]);

	// return hook output
	return [{ error, loading, data, response }, rerun];
}

/**
 * UseAPI configuration interface
 * @export
 */
export interface UseAPIConfig<Route extends APIRoute> extends Omit<AxiosRequestConfig, 'data' | 'params'> {
	data?: Route['Input'];
	params?: Route['Query'];
}

/**
 * UseAPI base options
 * @export
 */
export interface UseAPIOptions<Route extends APIRoute> extends UseAxiosOptions {
	onError?: (error: APIRequestError) => Promise<void> | void;
	onSuccess?: (output: Route['Output'], rawResponse: AxiosResponse<Route['Output']>) => Promise<void> | void;
}

/**
 * UseAPI options with format
 * @export
 */
export interface UseAPIOptionsWithFormat<Route extends APIRoute, FormattedOutput> extends Omit<UseAPIOptions<Route>, 'onSuccess'> {
	formatResult: (res: AxiosResponse<Route['Output']>) => Promise<FormattedOutput>;
	onSuccess?: (output: FormattedOutput, rawResponse: AxiosResponse<Route['Output']>) => Promise<void> | void;
}

/**
 * UseAPI output type
 * @export
 */
export type UseAPIOutput<Route extends APIRoute, FormattedOutput = undefined> = [
	ResponseValues<FormattedOutput extends undefined ? Route['Output'] : FormattedOutput, APIRequestError>,
	(config?: UseAPIConfig<Route>, options?: RefetchOptions) => AxiosPromise<FormattedOutput extends undefined ? Route['Output'] : FormattedOutput>,
];

/**
 * API route interface according to template in `./scripts/swagger/templates/route-type.eta`
 * @export
 */
export interface APIRoute {
	Params: Record<string, any>;
	Query: Record<string, any>;
	Input: unknown | never;
	Output: unknown;
}

export default useAPI;
