import Color from 'color';
import { OrderTicketsResultDto, Ticket } from '../models/passAPI';
import { EventCardColors, TicketCardColors } from '../models/common';

/**
 * Change global color scheme
 * @param {Color} bodyBg
 * @param {Color} bodyColor
 * @param {Color} footerBg
 */
export const changeGlobalColorScheme = (bodyBg?: Color, bodyColor?: Color, footerBg?: Color) => {
	document.documentElement.style.setProperty('--body-bg', bodyBg?.toString() || Color('#F5F5F5').toString());
	document.documentElement.style.setProperty('--body-color', bodyColor?.toString() || Color('#000000').toString());
	document.documentElement.style.setProperty('--footer-bg', footerBg?.toString() || bodyBg?.toString() || Color('#F5F5F5').toString());
};

/**
 * Return parsed Color from string
 * @param {string} color
 * @returns {Color | undefined}
 */
export const parseColorFromString = (color?: string): Color | undefined => {
	try {
		return color ? Color('#' + (color.charAt(0) === '#' ? color.substr(1) : color)) : undefined;
	} catch (e) {
		return undefined;
	}
};

/**
 * Determine correct color settings for eventCard
 * @param {Partial<EventCardColors | undefined>} configs
 * @returns {EventCardColors}
 */
export const determineEventColors = (...configs: Array<Partial<EventCardColors | undefined>>): EventCardColors => {
	const _configs: Array<Partial<EventCardColors>> = configs.filter(Boolean) as Array<Partial<EventCardColors>>;
	return {
		background: mapFilterConfigs(_configs, 'background')[0],
		text: mapFilterConfigs(_configs, 'text')[0],
		highlight: mapFilterConfigs(_configs, 'highlight')[0],
		link: mapFilterConfigs(_configs, 'link')[0],
		name: mapFilterConfigs(_configs, 'name')[0],
		subtitle: mapFilterConfigs(_configs, 'subtitle')[0],
	};
};

/**
 * Determine correct color settings for ticketCard
 * @param {Partial<TicketCardColors>} configs
 * @returns {TicketCardColors}
 */
export const determineTicketColors = (...configs: Array<Partial<TicketCardColors | undefined>>): TicketCardColors => {
	const _configs: Array<Partial<EventCardColors>> = configs.filter(Boolean) as Array<Partial<EventCardColors>>;
	return {
		background: mapFilterConfigs(_configs, 'background')[0],
		text: mapFilterConfigs(_configs, 'text')[0],
		icon: mapFilterConfigs(_configs, 'icon')[0],
	};
};

/**
 * Constructs partial TicketCardColors object from raw color inputs
 * @param {OrderTicketsResultDto["colorTicketCardBackground"]} background
 * @param {OrderTicketsResultDto["colorTicketCardText"]} text
 * @param {OrderTicketsResultDto["colorTicketCardIcon"]} icon
 * @return {Partial<TicketCardColors>}
 */
export const constructTicketColors = (
	background: OrderTicketsResultDto['colorTicketCardBackground'],
	text: OrderTicketsResultDto['colorTicketCardText'],
): Partial<TicketCardColors> => {
	return {
		background: parseColorFromString(background),
		text: parseColorFromString(text),
		icon: parseColorFromString(text),
	};
};

// determination map helper
function mapFilterConfigs(configs: Array<Partial<ColorConfig>>, key: keyof ColorConfig): Array<Color> {
	return configs.map((config) => config[key]).filter((config) => config !== undefined) as Color[];
}

type ColorConfig = EventCardColors & TicketCardColors;
